// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, Embedding2dCreateRequest, Embedding2dData, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, Embedding2dCreateRequestFromJSON, Embedding2dCreateRequestToJSON, Embedding2dCreateRequestValidator, Embedding2dDataFromJSON, Embedding2dDataToJSON, Embedding2dDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateEmbeddings2dByEmbeddingIdRequest {
    embedding2dCreateRequest: models.Embedding2dCreateRequest;
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateEmbeddings2dByEmbeddingIdRequestValidatorShape = {
    'embedding2dCreateRequest': models.Embedding2dCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateEmbeddings2dByEmbeddingIdRequestValidator = yup.object().shape(CreateEmbeddings2dByEmbeddingIdRequestValidatorShape);
export interface GetEmbedding2dByIdRequest {
    datasetId: string;
    embeddingId: string;
    embedding2dId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbedding2dByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embedding2dId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbedding2dByIdRequestValidator = yup.object().shape(GetEmbedding2dByIdRequestValidatorShape);
export interface GetEmbeddings2dByEmbeddingIdRequest {
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbeddings2dByEmbeddingIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddings2dByEmbeddingIdRequestValidator = yup.object().shape(GetEmbeddings2dByEmbeddingIdRequestValidatorShape);
export interface CreateEmbeddings2dByEmbeddingIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function CreateEmbeddings2dByEmbeddingIdRequestParamsSanitizer(json: any): CreateEmbeddings2dByEmbeddingIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const CreateEmbeddings2dByEmbeddingIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateEmbeddings2dByEmbeddingIdRequestParamsValidator = yup.object().shape(CreateEmbeddings2dByEmbeddingIdRequestParamsValidatorShape);
export interface GetEmbedding2dByIdRequestParams {
    datasetId: string;
    embeddingId: string;
    embedding2dId: string;
}
export function GetEmbedding2dByIdRequestParamsSanitizer(json: any): GetEmbedding2dByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
        'embedding2dId': json['embedding2dId'],
    };
}
export const GetEmbedding2dByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embedding2dId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbedding2dByIdRequestParamsValidator = yup.object().shape(GetEmbedding2dByIdRequestParamsValidatorShape);
export interface GetEmbeddings2dByEmbeddingIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function GetEmbeddings2dByEmbeddingIdRequestParamsSanitizer(json: any): GetEmbeddings2dByEmbeddingIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const GetEmbeddings2dByEmbeddingIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddings2dByEmbeddingIdRequestParamsValidator = yup.object().shape(GetEmbeddings2dByEmbeddingIdRequestParamsValidatorShape);
export interface CreateEmbeddings2dByEmbeddingIdRequestQuery {
}
export function CreateEmbeddings2dByEmbeddingIdRequestQuerySanitizer(json: any): CreateEmbeddings2dByEmbeddingIdRequestQuery {
    return {};
}
export const CreateEmbeddings2dByEmbeddingIdRequestQueryValidatorShape = {};
export const CreateEmbeddings2dByEmbeddingIdRequestQueryValidator = yup.object().shape(CreateEmbeddings2dByEmbeddingIdRequestQueryValidatorShape);
export interface GetEmbedding2dByIdRequestQuery {
}
export function GetEmbedding2dByIdRequestQuerySanitizer(json: any): GetEmbedding2dByIdRequestQuery {
    return {};
}
export const GetEmbedding2dByIdRequestQueryValidatorShape = {};
export const GetEmbedding2dByIdRequestQueryValidator = yup.object().shape(GetEmbedding2dByIdRequestQueryValidatorShape);
export interface GetEmbeddings2dByEmbeddingIdRequestQuery {
}
export function GetEmbeddings2dByEmbeddingIdRequestQuerySanitizer(json: any): GetEmbeddings2dByEmbeddingIdRequestQuery {
    return {};
}
export const GetEmbeddings2dByEmbeddingIdRequestQueryValidatorShape = {};
export const GetEmbeddings2dByEmbeddingIdRequestQueryValidator = yup.object().shape(GetEmbeddings2dByEmbeddingIdRequestQueryValidatorShape);
export type CreateEmbeddings2dByEmbeddingIdRequestBody = Embedding2dCreateRequest;
export const CreateEmbeddings2dByEmbeddingIdRequestBodySanitizer = Embedding2dCreateRequestFromJSON;
export const CreateEmbeddings2dByEmbeddingIdRequestBodyValidator = Embedding2dCreateRequestValidator;
export const CreateEmbeddings2dByEmbeddingIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateEmbeddings2dByEmbeddingIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateEmbeddings2dByEmbeddingIdRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && ("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 && $io1(input.coordinatesDimension1, true && _exceptionable)) && ("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 && $io1(input.coordinatesDimension2, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "dimensionalityReductionMethod", "coordinatesDimension1", "coordinatesDimension2"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateEmbeddings2dByEmbeddingIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && $ao1(input.coordinatesDimension1, _path + ".coordinatesDimension1", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && (("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && $ao1(input.coordinatesDimension2, _path + ".coordinatesDimension2", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "dimensionalityReductionMethod", "coordinatesDimension1", "coordinatesDimension2"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateEmbeddings2dByEmbeddingIdTypiaAssertStringify = (input: CreateEmbeddings2dByEmbeddingIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateEmbeddings2dByEmbeddingIdRequestBody => {
        const __is = (input: any): input is CreateEmbeddings2dByEmbeddingIdRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.name && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && ("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 && $io1(input.coordinatesDimension1)) && ("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 && $io1(input.coordinatesDimension2));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateEmbeddings2dByEmbeddingIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (("object" === typeof input.coordinatesDimension1 && null !== input.coordinatesDimension1 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && $ao1(input.coordinatesDimension1, _path + ".coordinatesDimension1", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension1",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension1
                })) && (("object" === typeof input.coordinatesDimension2 && null !== input.coordinatesDimension2 || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                })) && $ao1(input.coordinatesDimension2, _path + ".coordinatesDimension2", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".coordinatesDimension2",
                    expected: "Embedding2dCoordinates",
                    value: input.coordinatesDimension2
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Embedding2dCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateEmbeddings2dByEmbeddingIdRequestBody): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{"name":${$string(input.name)},"dimensionalityReductionMethod":${(() => {
            if ("string" === typeof input.dimensionalityReductionMethod)
                return $string(input.dimensionalityReductionMethod);
            if ("string" === typeof input.dimensionalityReductionMethod)
                return "\"" + input.dimensionalityReductionMethod + "\"";
            $throws({
                expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                value: input.dimensionalityReductionMethod
            });
        })()},"coordinatesDimension1":${$so1(input.coordinatesDimension1)},"coordinatesDimension2":${$so1(input.coordinatesDimension2)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d

export const createEmbeddings2dByEmbeddingIdFN: RequestHandlerAsync<
    CreateEmbeddings2dByEmbeddingIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateEmbeddings2dByEmbeddingIdRequestBody,
    CreateEmbeddings2dByEmbeddingIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateEmbeddings2dByEmbeddingIdRequestBodySanitizer, CreateEmbeddings2dByEmbeddingIdRequestBodyValidator);
    const {
        
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, CreateEmbeddings2dByEmbeddingIdRequestParamsSanitizer, CreateEmbeddings2dByEmbeddingIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, CreateEmbeddings2dByEmbeddingIdRequestQuerySanitizer, CreateEmbeddings2dByEmbeddingIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d/{embedding2dId}

export const getEmbedding2dByIdFN: RequestHandlerAsync<
    GetEmbedding2dByIdRequestParams,
    Embedding2dData | ApiErrorResponse,
    GetEmbedding2dByIdRequestBody,
    GetEmbedding2dByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbedding2dByIdRequestBodySanitizer, GetEmbedding2dByIdRequestBodyValidator);
    const {
        datasetId,
        embeddingId,
        embedding2dId,
    } =  await validateAndSanitize(req.params, GetEmbedding2dByIdRequestParamsSanitizer, GetEmbedding2dByIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, GetEmbedding2dByIdRequestQuerySanitizer, GetEmbedding2dByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Embedding2dDataStringify,
        validateAndRespond({ id, ...rest }, Embedding2dDataFromJSON, Embedding2dDataValidator),
    );
}



/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d
const Embedding2dDataArrayStringify = fastJSONArrayify(Embedding2dDataStringifyShape);
export const getEmbeddings2dByEmbeddingIdFN: RequestHandlerAsync<
    GetEmbeddings2dByEmbeddingIdRequestParams,
    Embedding2dData[] | ApiErrorResponse,
    GetEmbeddings2dByEmbeddingIdRequestBody,
    GetEmbeddings2dByEmbeddingIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbeddings2dByEmbeddingIdRequestBodySanitizer, GetEmbeddings2dByEmbeddingIdRequestBodyValidator);
    const {
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, GetEmbeddings2dByEmbeddingIdRequestParamsSanitizer, GetEmbeddings2dByEmbeddingIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetEmbeddings2dByEmbeddingIdRequestQuerySanitizer, GetEmbeddings2dByEmbeddingIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        Embedding2dDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, Embedding2dDataFromJSON, Embedding2dDataValidator);
        }),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * Embeddings2dApi - interface
 *
 * @export
 * @interface Embeddings2dApiInterface
 */
export interface Embeddings2dApiInterface {
    /**
     * Create a new 2d embedding
     * @param {Embedding2dCreateRequest} embedding2dCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Embeddings2dApiInterface
     */
    createEmbeddings2dByEmbeddingIdRaw(requestParameters: CreateEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create a new 2d embedding
     */
    createEmbeddings2dByEmbeddingId(requestParameters: CreateEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Get the 2d embeddings by id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {string} embedding2dId ObjectId of the 2d embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Embeddings2dApiInterface
     */
    getEmbedding2dByIdRaw(requestParameters: GetEmbedding2dByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Embedding2dData>>;
    /**
     * Get the 2d embeddings by id
     */
    getEmbedding2dById(requestParameters: GetEmbedding2dByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Embedding2dData>;
    /**
     * Get all 2d embeddings of an embedding
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Embeddings2dApiInterface
     */
    getEmbeddings2dByEmbeddingIdRaw(requestParameters: GetEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Embedding2dData>>>;
    /**
     * Get all 2d embeddings of an embedding
     */
    getEmbeddings2dByEmbeddingId(requestParameters: GetEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Embedding2dData>>;
}
/**
 *
 */
export class Embeddings2dApi extends runtime.BaseAPI implements Embeddings2dApiInterface {
    /**
     * Create a new 2d embedding
     */
    async createEmbeddings2dByEmbeddingIdRaw(requestParameters: CreateEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.embedding2dCreateRequest === null || requestParameters.embedding2dCreateRequest === undefined) {
            throw new runtime.RequiredError('embedding2dCreateRequest', 'Required parameter requestParameters.embedding2dCreateRequest was null or undefined when calling createEmbeddings2dByEmbeddingId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createEmbeddings2dByEmbeddingId.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling createEmbeddings2dByEmbeddingId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Embedding2dCreateRequestToJSON(requestParameters.embedding2dCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create a new 2d embedding
     */
    async createEmbeddings2dByEmbeddingId(requestParameters: CreateEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createEmbeddings2dByEmbeddingIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the 2d embeddings by id
     */
    async getEmbedding2dByIdRaw(requestParameters: GetEmbedding2dByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Embedding2dData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbedding2dById.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling getEmbedding2dById.');
        }
        if (requestParameters.embedding2dId === null || requestParameters.embedding2dId === undefined) {
            throw new runtime.RequiredError('embedding2dId', 'Required parameter requestParameters.embedding2dId was null or undefined when calling getEmbedding2dById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d/{embedding2dId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))).replace(`{${"embedding2dId"}}`, encodeURIComponent(String(requestParameters.embedding2dId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => Embedding2dDataFromJSON(jsonValue));
    }
    /**
     * Get the 2d embeddings by id
     */
    async getEmbedding2dById(requestParameters: GetEmbedding2dByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Embedding2dData> {
        const response = await this.getEmbedding2dByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all 2d embeddings of an embedding
     */
    async getEmbeddings2dByEmbeddingIdRaw(requestParameters: GetEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Embedding2dData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddings2dByEmbeddingId.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling getEmbeddings2dByEmbeddingId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/2d`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(Embedding2dDataFromJSON));
    }
    /**
     * Get all 2d embeddings of an embedding
     */
    async getEmbeddings2dByEmbeddingId(requestParameters: GetEmbeddings2dByEmbeddingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Embedding2dData>> {
        const response = await this.getEmbeddings2dByEmbeddingIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
