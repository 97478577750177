// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, SharedAccessConfigCreateRequest, SharedAccessConfigData, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, SharedAccessConfigCreateRequestFromJSON, SharedAccessConfigCreateRequestToJSON, SharedAccessConfigCreateRequestValidator, SharedAccessConfigDataFromJSON, SharedAccessConfigDataToJSON, SharedAccessConfigDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateOrUpdateSharedAccessConfigByDatasetIdRequest {
    sharedAccessConfigCreateRequest: models.SharedAccessConfigCreateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestValidatorShape = {
    'sharedAccessConfigCreateRequest': models.SharedAccessConfigCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestValidator = yup.object().shape(CreateOrUpdateSharedAccessConfigByDatasetIdRequestValidatorShape);
export interface DeleteSharedAccessConfigByIdRequest {
    datasetId: string;
    accessConfigId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const DeleteSharedAccessConfigByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'accessConfigId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteSharedAccessConfigByIdRequestValidator = yup.object().shape(DeleteSharedAccessConfigByIdRequestValidatorShape);
export interface GetSharedAccessConfigsByDatasetIdRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSharedAccessConfigsByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSharedAccessConfigsByDatasetIdRequestValidator = yup.object().shape(GetSharedAccessConfigsByDatasetIdRequestValidatorShape);
export interface CreateOrUpdateSharedAccessConfigByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsSanitizer(json: any): CreateOrUpdateSharedAccessConfigByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsValidator = yup.object().shape(CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsValidatorShape);
export interface DeleteSharedAccessConfigByIdRequestParams {
    datasetId: string;
    accessConfigId: string;
}
export function DeleteSharedAccessConfigByIdRequestParamsSanitizer(json: any): DeleteSharedAccessConfigByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'accessConfigId': json['accessConfigId'],
    };
}
export const DeleteSharedAccessConfigByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'accessConfigId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteSharedAccessConfigByIdRequestParamsValidator = yup.object().shape(DeleteSharedAccessConfigByIdRequestParamsValidatorShape);
export interface GetSharedAccessConfigsByDatasetIdRequestParams {
    datasetId: string;
}
export function GetSharedAccessConfigsByDatasetIdRequestParamsSanitizer(json: any): GetSharedAccessConfigsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetSharedAccessConfigsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSharedAccessConfigsByDatasetIdRequestParamsValidator = yup.object().shape(GetSharedAccessConfigsByDatasetIdRequestParamsValidatorShape);
export interface CreateOrUpdateSharedAccessConfigByDatasetIdRequestQuery {
}
export function CreateOrUpdateSharedAccessConfigByDatasetIdRequestQuerySanitizer(json: any): CreateOrUpdateSharedAccessConfigByDatasetIdRequestQuery {
    return {};
}
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestQueryValidatorShape = {};
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestQueryValidator = yup.object().shape(CreateOrUpdateSharedAccessConfigByDatasetIdRequestQueryValidatorShape);
export interface DeleteSharedAccessConfigByIdRequestQuery {
}
export function DeleteSharedAccessConfigByIdRequestQuerySanitizer(json: any): DeleteSharedAccessConfigByIdRequestQuery {
    return {};
}
export const DeleteSharedAccessConfigByIdRequestQueryValidatorShape = {};
export const DeleteSharedAccessConfigByIdRequestQueryValidator = yup.object().shape(DeleteSharedAccessConfigByIdRequestQueryValidatorShape);
export interface GetSharedAccessConfigsByDatasetIdRequestQuery {
}
export function GetSharedAccessConfigsByDatasetIdRequestQuerySanitizer(json: any): GetSharedAccessConfigsByDatasetIdRequestQuery {
    return {};
}
export const GetSharedAccessConfigsByDatasetIdRequestQueryValidatorShape = {};
export const GetSharedAccessConfigsByDatasetIdRequestQueryValidator = yup.object().shape(GetSharedAccessConfigsByDatasetIdRequestQueryValidatorShape);
export type CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody = SharedAccessConfigCreateRequest;
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestBodySanitizer = SharedAccessConfigCreateRequestFromJSON;
export const CreateOrUpdateSharedAccessConfigByDatasetIdRequestBodyValidator = SharedAccessConfigCreateRequestValidator;
export const CreateOrUpdateSharedAccessConfigByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "WRITE" === input.accessType && (undefined === input.users || Array.isArray(input.users) && input.users.every((elem: any, _index1: number) => "string" === typeof elem)) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["accessType", "users", "teams", "creator"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && (undefined === input.users || (Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["accessType", "users", "teams", "creator"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateOrUpdateSharedAccessConfigByDatasetIdTypiaAssertStringify = (input: CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
            const $io0 = (input: any): boolean => "WRITE" === input.accessType && (undefined === input.users || Array.isArray(input.users) && input.users.every((elem: any) => "string" === typeof elem)) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any) => "string" === typeof elem)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && (undefined === input.users || (Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.users ? "" : `"users":${undefined !== input.users ? `[${input.users.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.teams ? "" : `"teams":${undefined !== input.teams ? `[${input.teams.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"accessType":${(() => {
            if ("string" === typeof input.accessType)
                return $string(input.accessType);
            if ("string" === typeof input.accessType)
                return "\"" + input.accessType + "\"";
            $throws({
                expected: "\"WRITE\"",
                value: input.accessType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/collaboration/access

export const createOrUpdateSharedAccessConfigByDatasetIdFN: RequestHandlerAsync<
    CreateOrUpdateSharedAccessConfigByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateOrUpdateSharedAccessConfigByDatasetIdRequestBody,
    CreateOrUpdateSharedAccessConfigByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateOrUpdateSharedAccessConfigByDatasetIdRequestBodySanitizer, CreateOrUpdateSharedAccessConfigByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsSanitizer, CreateOrUpdateSharedAccessConfigByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateOrUpdateSharedAccessConfigByDatasetIdRequestQuerySanitizer, CreateOrUpdateSharedAccessConfigByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/collaboration/access/{accessConfigId}

export const deleteSharedAccessConfigByIdFN: RequestHandlerAsync<
    DeleteSharedAccessConfigByIdRequestParams,
     | ApiErrorResponse,
    DeleteSharedAccessConfigByIdRequestBody,
    DeleteSharedAccessConfigByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DeleteSharedAccessConfigByIdRequestBodySanitizer, DeleteSharedAccessConfigByIdRequestBodyValidator);
    const {
        datasetId,
        accessConfigId,
    } =  await validateAndSanitize(req.params, DeleteSharedAccessConfigByIdRequestParamsSanitizer, DeleteSharedAccessConfigByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, DeleteSharedAccessConfigByIdRequestQuerySanitizer, DeleteSharedAccessConfigByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/collaboration/access
const SharedAccessConfigDataArrayStringify = fastJSONArrayify(SharedAccessConfigDataStringifyShape);
export const getSharedAccessConfigsByDatasetIdFN: RequestHandlerAsync<
    GetSharedAccessConfigsByDatasetIdRequestParams,
    SharedAccessConfigData[] | ApiErrorResponse,
    GetSharedAccessConfigsByDatasetIdRequestBody,
    GetSharedAccessConfigsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSharedAccessConfigsByDatasetIdRequestBodySanitizer, GetSharedAccessConfigsByDatasetIdRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetSharedAccessConfigsByDatasetIdRequestParamsSanitizer, GetSharedAccessConfigsByDatasetIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetSharedAccessConfigsByDatasetIdRequestQuerySanitizer, GetSharedAccessConfigsByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        SharedAccessConfigDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, SharedAccessConfigDataFromJSON, SharedAccessConfigDataValidator);
        }),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * CollaborationApi - interface
 *
 * @export
 * @interface CollaborationApiInterface
 */
export interface CollaborationApiInterface {
    /**
     * Create or update a shared access config.
     * @param {SharedAccessConfigCreateRequest} sharedAccessConfigCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollaborationApiInterface
     */
    createOrUpdateSharedAccessConfigByDatasetIdRaw(requestParameters: CreateOrUpdateSharedAccessConfigByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create or update a shared access config.
     */
    createOrUpdateSharedAccessConfigByDatasetId(requestParameters: CreateOrUpdateSharedAccessConfigByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Delete shared access config by id.
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} accessConfigId ObjectId of the shared access config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollaborationApiInterface
     */
    deleteSharedAccessConfigByIdRaw(requestParameters: DeleteSharedAccessConfigByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Delete shared access config by id.
     */
    deleteSharedAccessConfigById(requestParameters: DeleteSharedAccessConfigByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Get shared access configs by datasetId.
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollaborationApiInterface
     */
    getSharedAccessConfigsByDatasetIdRaw(requestParameters: GetSharedAccessConfigsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedAccessConfigData>>>;
    /**
     * Get shared access configs by datasetId.
     */
    getSharedAccessConfigsByDatasetId(requestParameters: GetSharedAccessConfigsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedAccessConfigData>>;
}
/**
 *
 */
export class CollaborationApi extends runtime.BaseAPI implements CollaborationApiInterface {
    /**
     * Create or update a shared access config.
     */
    async createOrUpdateSharedAccessConfigByDatasetIdRaw(requestParameters: CreateOrUpdateSharedAccessConfigByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.sharedAccessConfigCreateRequest === null || requestParameters.sharedAccessConfigCreateRequest === undefined) {
            throw new runtime.RequiredError('sharedAccessConfigCreateRequest', 'Required parameter requestParameters.sharedAccessConfigCreateRequest was null or undefined when calling createOrUpdateSharedAccessConfigByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createOrUpdateSharedAccessConfigByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/collaboration/access`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SharedAccessConfigCreateRequestToJSON(requestParameters.sharedAccessConfigCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create or update a shared access config.
     */
    async createOrUpdateSharedAccessConfigByDatasetId(requestParameters: CreateOrUpdateSharedAccessConfigByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createOrUpdateSharedAccessConfigByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Delete shared access config by id.
     */
    async deleteSharedAccessConfigByIdRaw(requestParameters: DeleteSharedAccessConfigByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling deleteSharedAccessConfigById.');
        }
        if (requestParameters.accessConfigId === null || requestParameters.accessConfigId === undefined) {
            throw new runtime.RequiredError('accessConfigId', 'Required parameter requestParameters.accessConfigId was null or undefined when calling deleteSharedAccessConfigById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/collaboration/access/{accessConfigId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"accessConfigId"}}`, encodeURIComponent(String(requestParameters.accessConfigId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete shared access config by id.
     */
    async deleteSharedAccessConfigById(requestParameters: DeleteSharedAccessConfigByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSharedAccessConfigByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Get shared access configs by datasetId.
     */
    async getSharedAccessConfigsByDatasetIdRaw(requestParameters: GetSharedAccessConfigsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedAccessConfigData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSharedAccessConfigsByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/collaboration/access`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedAccessConfigDataFromJSON));
    }
    /**
     * Get shared access configs by datasetId.
     */
    async getSharedAccessConfigsByDatasetId(requestParameters: GetSharedAccessConfigsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedAccessConfigData>> {
        const response = await this.getSharedAccessConfigsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
