// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, DatasetEmbeddingData, EmbeddingData, SetEmbeddingsIsProcessedFlagByIdBodyRequest, Trigger2dEmbeddingJobRequest, WriteCSVUrlData, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, DatasetEmbeddingDataFromJSON, DatasetEmbeddingDataToJSON, DatasetEmbeddingDataValidator, EmbeddingDataFromJSON, EmbeddingDataToJSON, EmbeddingDataValidator, SetEmbeddingsIsProcessedFlagByIdBodyRequestFromJSON, SetEmbeddingsIsProcessedFlagByIdBodyRequestToJSON, SetEmbeddingsIsProcessedFlagByIdBodyRequestValidator, Trigger2dEmbeddingJobRequestFromJSON, Trigger2dEmbeddingJobRequestToJSON, Trigger2dEmbeddingJobRequestValidator, WriteCSVUrlDataFromJSON, WriteCSVUrlDataToJSON, WriteCSVUrlDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/**
  * @export
  * @enum {string}
  */
export enum GetEmbeddingsBySampleIdModeEnum {
    full = 'full'
}
/* LIGHTLY CUSTOM START ->  apiMustache */
export const GetEmbeddingsBySampleIdModeEnumValidatorShape = [
    'full'
];
export const GetEmbeddingsBySampleIdModeEnumValidator = yup.mixed().oneOf(GetEmbeddingsBySampleIdModeEnumValidatorShape);
/* LIGHTLY CUSTOM END ->  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface DeleteEmbeddingByIdRequest {
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const DeleteEmbeddingByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteEmbeddingByIdRequestValidator = yup.object().shape(DeleteEmbeddingByIdRequestValidatorShape);
export interface GetEmbeddingsByDatasetIdRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbeddingsByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsByDatasetIdRequestValidator = yup.object().shape(GetEmbeddingsByDatasetIdRequestValidatorShape);
export interface GetEmbeddingsBySampleIdRequest {
    datasetId: string;
    sampleId: string;
    mode?: GetEmbeddingsBySampleIdModeEnum;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbeddingsBySampleIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'mode': yup.string().oneOf(GetEmbeddingsBySampleIdModeEnumValidatorShape),
};
export const GetEmbeddingsBySampleIdRequestValidator = yup.object().shape(GetEmbeddingsBySampleIdRequestValidatorShape);
export interface GetEmbeddingsCSVReadUrlByIdRequest {
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbeddingsCSVReadUrlByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsCSVReadUrlByIdRequestValidator = yup.object().shape(GetEmbeddingsCSVReadUrlByIdRequestValidatorShape);
export interface GetEmbeddingsCSVWriteUrlByIdRequest {
    datasetId: string;
    name?: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetEmbeddingsCSVWriteUrlByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'name': yup.string(),
};
export const GetEmbeddingsCSVWriteUrlByIdRequestValidator = yup.object().shape(GetEmbeddingsCSVWriteUrlByIdRequestValidatorShape);
export interface SetEmbeddingsIsProcessedFlagByIdRequest {
    setEmbeddingsIsProcessedFlagByIdBodyRequest: models.SetEmbeddingsIsProcessedFlagByIdBodyRequest;
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const SetEmbeddingsIsProcessedFlagByIdRequestValidatorShape = {
    'setEmbeddingsIsProcessedFlagByIdBodyRequest': models.SetEmbeddingsIsProcessedFlagByIdBodyRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const SetEmbeddingsIsProcessedFlagByIdRequestValidator = yup.object().shape(SetEmbeddingsIsProcessedFlagByIdRequestValidatorShape);
export interface Trigger2dEmbeddingsJobRequest {
    trigger2dEmbeddingJobRequest: models.Trigger2dEmbeddingJobRequest;
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const Trigger2dEmbeddingsJobRequestValidatorShape = {
    'trigger2dEmbeddingJobRequest': models.Trigger2dEmbeddingJobRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const Trigger2dEmbeddingsJobRequestValidator = yup.object().shape(Trigger2dEmbeddingsJobRequestValidatorShape);
export interface DeleteEmbeddingByIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function DeleteEmbeddingByIdRequestParamsSanitizer(json: any): DeleteEmbeddingByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const DeleteEmbeddingByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteEmbeddingByIdRequestParamsValidator = yup.object().shape(DeleteEmbeddingByIdRequestParamsValidatorShape);
export interface GetEmbeddingsByDatasetIdRequestParams {
    datasetId: string;
}
export function GetEmbeddingsByDatasetIdRequestParamsSanitizer(json: any): GetEmbeddingsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetEmbeddingsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsByDatasetIdRequestParamsValidator = yup.object().shape(GetEmbeddingsByDatasetIdRequestParamsValidatorShape);
export interface GetEmbeddingsBySampleIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetEmbeddingsBySampleIdRequestParamsSanitizer(json: any): GetEmbeddingsBySampleIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetEmbeddingsBySampleIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsBySampleIdRequestParamsValidator = yup.object().shape(GetEmbeddingsBySampleIdRequestParamsValidatorShape);
export interface GetEmbeddingsCSVReadUrlByIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function GetEmbeddingsCSVReadUrlByIdRequestParamsSanitizer(json: any): GetEmbeddingsCSVReadUrlByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const GetEmbeddingsCSVReadUrlByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsCSVReadUrlByIdRequestParamsValidator = yup.object().shape(GetEmbeddingsCSVReadUrlByIdRequestParamsValidatorShape);
export interface GetEmbeddingsCSVWriteUrlByIdRequestParams {
    datasetId: string;
}
export function GetEmbeddingsCSVWriteUrlByIdRequestParamsSanitizer(json: any): GetEmbeddingsCSVWriteUrlByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetEmbeddingsCSVWriteUrlByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetEmbeddingsCSVWriteUrlByIdRequestParamsValidator = yup.object().shape(GetEmbeddingsCSVWriteUrlByIdRequestParamsValidatorShape);
export interface SetEmbeddingsIsProcessedFlagByIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function SetEmbeddingsIsProcessedFlagByIdRequestParamsSanitizer(json: any): SetEmbeddingsIsProcessedFlagByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const SetEmbeddingsIsProcessedFlagByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const SetEmbeddingsIsProcessedFlagByIdRequestParamsValidator = yup.object().shape(SetEmbeddingsIsProcessedFlagByIdRequestParamsValidatorShape);
export interface Trigger2dEmbeddingsJobRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function Trigger2dEmbeddingsJobRequestParamsSanitizer(json: any): Trigger2dEmbeddingsJobRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const Trigger2dEmbeddingsJobRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const Trigger2dEmbeddingsJobRequestParamsValidator = yup.object().shape(Trigger2dEmbeddingsJobRequestParamsValidatorShape);
export interface DeleteEmbeddingByIdRequestQuery {
}
export function DeleteEmbeddingByIdRequestQuerySanitizer(json: any): DeleteEmbeddingByIdRequestQuery {
    return {};
}
export const DeleteEmbeddingByIdRequestQueryValidatorShape = {};
export const DeleteEmbeddingByIdRequestQueryValidator = yup.object().shape(DeleteEmbeddingByIdRequestQueryValidatorShape);
export interface GetEmbeddingsByDatasetIdRequestQuery {
}
export function GetEmbeddingsByDatasetIdRequestQuerySanitizer(json: any): GetEmbeddingsByDatasetIdRequestQuery {
    return {};
}
export const GetEmbeddingsByDatasetIdRequestQueryValidatorShape = {};
export const GetEmbeddingsByDatasetIdRequestQueryValidator = yup.object().shape(GetEmbeddingsByDatasetIdRequestQueryValidatorShape);
export interface GetEmbeddingsBySampleIdRequestQuery {
    mode?: GetEmbeddingsBySampleIdModeEnum;
}
export function GetEmbeddingsBySampleIdRequestQuerySanitizer(json: any): GetEmbeddingsBySampleIdRequestQuery {
    return {
        'mode': json['mode'],
    };
}
export const GetEmbeddingsBySampleIdRequestQueryValidatorShape = {
    'mode': yup.string().oneOf(GetEmbeddingsBySampleIdModeEnumValidatorShape),
};
export const GetEmbeddingsBySampleIdRequestQueryValidator = yup.object().shape(GetEmbeddingsBySampleIdRequestQueryValidatorShape);
export interface GetEmbeddingsCSVReadUrlByIdRequestQuery {
}
export function GetEmbeddingsCSVReadUrlByIdRequestQuerySanitizer(json: any): GetEmbeddingsCSVReadUrlByIdRequestQuery {
    return {};
}
export const GetEmbeddingsCSVReadUrlByIdRequestQueryValidatorShape = {};
export const GetEmbeddingsCSVReadUrlByIdRequestQueryValidator = yup.object().shape(GetEmbeddingsCSVReadUrlByIdRequestQueryValidatorShape);
export interface GetEmbeddingsCSVWriteUrlByIdRequestQuery {
    name?: string;
}
export function GetEmbeddingsCSVWriteUrlByIdRequestQuerySanitizer(json: any): GetEmbeddingsCSVWriteUrlByIdRequestQuery {
    return {
        'name': json['name'],
    };
}
export const GetEmbeddingsCSVWriteUrlByIdRequestQueryValidatorShape = {
    'name': yup.string(),
};
export const GetEmbeddingsCSVWriteUrlByIdRequestQueryValidator = yup.object().shape(GetEmbeddingsCSVWriteUrlByIdRequestQueryValidatorShape);
export interface SetEmbeddingsIsProcessedFlagByIdRequestQuery {
}
export function SetEmbeddingsIsProcessedFlagByIdRequestQuerySanitizer(json: any): SetEmbeddingsIsProcessedFlagByIdRequestQuery {
    return {};
}
export const SetEmbeddingsIsProcessedFlagByIdRequestQueryValidatorShape = {};
export const SetEmbeddingsIsProcessedFlagByIdRequestQueryValidator = yup.object().shape(SetEmbeddingsIsProcessedFlagByIdRequestQueryValidatorShape);
export interface Trigger2dEmbeddingsJobRequestQuery {
}
export function Trigger2dEmbeddingsJobRequestQuerySanitizer(json: any): Trigger2dEmbeddingsJobRequestQuery {
    return {};
}
export const Trigger2dEmbeddingsJobRequestQueryValidatorShape = {};
export const Trigger2dEmbeddingsJobRequestQueryValidator = yup.object().shape(Trigger2dEmbeddingsJobRequestQueryValidatorShape);
export type SetEmbeddingsIsProcessedFlagByIdRequestBody = SetEmbeddingsIsProcessedFlagByIdBodyRequest;
export const SetEmbeddingsIsProcessedFlagByIdRequestBodySanitizer = SetEmbeddingsIsProcessedFlagByIdBodyRequestFromJSON;
export const SetEmbeddingsIsProcessedFlagByIdRequestBodyValidator = SetEmbeddingsIsProcessedFlagByIdBodyRequestValidator;
export const SetEmbeddingsIsProcessedFlagByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): SetEmbeddingsIsProcessedFlagByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.rowCount && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["rowCount"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.rowCount || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "number",
                    value: input.rowCount
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["rowCount"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SetEmbeddingsIsProcessedFlagByIdTypiaAssertStringify = (input: SetEmbeddingsIsProcessedFlagByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): SetEmbeddingsIsProcessedFlagByIdRequestBody => {
        const __is = (input: any): input is SetEmbeddingsIsProcessedFlagByIdRequestBody => {
            return "object" === typeof input && null !== input && ("number" === typeof (input as any).rowCount && !Number.isNaN((input as any).rowCount));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SetEmbeddingsIsProcessedFlagByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "number" === typeof input.rowCount && !Number.isNaN(input.rowCount) || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "number",
                    value: input.rowCount
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SetEmbeddingsIsProcessedFlagByIdBodyRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SetEmbeddingsIsProcessedFlagByIdRequestBody): string => {
        return `{"rowCount":${(input as any).rowCount}}`;
    }; return stringify(assert(input)); })(input);
};
export type Trigger2dEmbeddingsJobRequestBody = Trigger2dEmbeddingJobRequest;
export const Trigger2dEmbeddingsJobRequestBodySanitizer = Trigger2dEmbeddingJobRequestFromJSON;
export const Trigger2dEmbeddingsJobRequestBodyValidator = Trigger2dEmbeddingJobRequestValidator;
export const Trigger2dEmbeddingsJobTypiaAssertEquals = (input: any) => {
    return ((input: any): Trigger2dEmbeddingsJobRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is Trigger2dEmbeddingsJobRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["dimensionalityReductionMethod"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Trigger2dEmbeddingsJobRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["dimensionalityReductionMethod"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const Trigger2dEmbeddingsJobTypiaAssertStringify = (input: Trigger2dEmbeddingsJobRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): Trigger2dEmbeddingsJobRequestBody => {
        const __is = (input: any): input is Trigger2dEmbeddingsJobRequestBody => {
            const $io0 = (input: any): boolean => "PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Trigger2dEmbeddingsJobRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: Trigger2dEmbeddingsJobRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"dimensionalityReductionMethod":${(() => {
            if ("string" === typeof input.dimensionalityReductionMethod)
                return $string(input.dimensionalityReductionMethod);
            if ("string" === typeof input.dimensionalityReductionMethod)
                return "\"" + input.dimensionalityReductionMethod + "\"";
            $throws({
                expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                value: input.dimensionalityReductionMethod
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/embeddings/{embeddingId}

export const deleteEmbeddingByIdFN: RequestHandlerAsync<
    DeleteEmbeddingByIdRequestParams,
     | ApiErrorResponse,
    DeleteEmbeddingByIdRequestBody,
    DeleteEmbeddingByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DeleteEmbeddingByIdRequestBodySanitizer, DeleteEmbeddingByIdRequestBodyValidator);
    const {
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, DeleteEmbeddingByIdRequestParamsSanitizer, DeleteEmbeddingByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, DeleteEmbeddingByIdRequestQuerySanitizer, DeleteEmbeddingByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/embeddings
const DatasetEmbeddingDataArrayStringify = fastJSONArrayify(DatasetEmbeddingDataStringifyShape);
export const getEmbeddingsByDatasetIdFN: RequestHandlerAsync<
    GetEmbeddingsByDatasetIdRequestParams,
    DatasetEmbeddingData[] | ApiErrorResponse,
    GetEmbeddingsByDatasetIdRequestBody,
    GetEmbeddingsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbeddingsByDatasetIdRequestBodySanitizer, GetEmbeddingsByDatasetIdRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetEmbeddingsByDatasetIdRequestParamsSanitizer, GetEmbeddingsByDatasetIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetEmbeddingsByDatasetIdRequestQuerySanitizer, GetEmbeddingsByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetEmbeddingDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetEmbeddingDataFromJSON, DatasetEmbeddingDataValidator);
        }),
    );
}



/users/datasets/{datasetId}/samples/{sampleId}/embeddings
const EmbeddingDataArrayStringify = fastJSONArrayify(EmbeddingDataStringifyShape);
export const getEmbeddingsBySampleIdFN: RequestHandlerAsync<
    GetEmbeddingsBySampleIdRequestParams,
    EmbeddingData[] | ApiErrorResponse,
    GetEmbeddingsBySampleIdRequestBody,
    GetEmbeddingsBySampleIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbeddingsBySampleIdRequestBodySanitizer, GetEmbeddingsBySampleIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, GetEmbeddingsBySampleIdRequestParamsSanitizer, GetEmbeddingsBySampleIdRequestParamsValidator);
    const {
        
        
        mode,
    } = await validateAndSanitize(req.query, GetEmbeddingsBySampleIdRequestQuerySanitizer, GetEmbeddingsBySampleIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        EmbeddingDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, EmbeddingDataFromJSON, EmbeddingDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/embeddings/{embeddingId}/readCSVUrl

export const getEmbeddingsCSVReadUrlByIdFN: RequestHandlerAsync<
    GetEmbeddingsCSVReadUrlByIdRequestParams,
    string | ApiErrorResponse,
    GetEmbeddingsCSVReadUrlByIdRequestBody,
    GetEmbeddingsCSVReadUrlByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbeddingsCSVReadUrlByIdRequestBodySanitizer, GetEmbeddingsCSVReadUrlByIdRequestBodyValidator);
    const {
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, GetEmbeddingsCSVReadUrlByIdRequestParamsSanitizer, GetEmbeddingsCSVReadUrlByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetEmbeddingsCSVReadUrlByIdRequestQuerySanitizer, GetEmbeddingsCSVReadUrlByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/datasets/{datasetId}/embeddings/writeCSVUrl

export const getEmbeddingsCSVWriteUrlByIdFN: RequestHandlerAsync<
    GetEmbeddingsCSVWriteUrlByIdRequestParams,
    WriteCSVUrlData | ApiErrorResponse,
    GetEmbeddingsCSVWriteUrlByIdRequestBody,
    GetEmbeddingsCSVWriteUrlByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetEmbeddingsCSVWriteUrlByIdRequestBodySanitizer, GetEmbeddingsCSVWriteUrlByIdRequestBodyValidator);
    const {
        datasetId,
        
    } =  await validateAndSanitize(req.params, GetEmbeddingsCSVWriteUrlByIdRequestParamsSanitizer, GetEmbeddingsCSVWriteUrlByIdRequestParamsValidator);
    const {
        
        name,
    } = await validateAndSanitize(req.query, GetEmbeddingsCSVWriteUrlByIdRequestQuerySanitizer, GetEmbeddingsCSVWriteUrlByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        WriteCSVUrlDataStringify,
        validateAndRespond({ id, ...rest }, WriteCSVUrlDataFromJSON, WriteCSVUrlDataValidator),
    );
}



/v1/datasets/{datasetId}/embeddings/{embeddingId}/isProcessed

export const setEmbeddingsIsProcessedFlagByIdFN: RequestHandlerAsync<
    SetEmbeddingsIsProcessedFlagByIdRequestParams,
     | ApiErrorResponse,
    SetEmbeddingsIsProcessedFlagByIdRequestBody,
    SetEmbeddingsIsProcessedFlagByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, SetEmbeddingsIsProcessedFlagByIdRequestBodySanitizer, SetEmbeddingsIsProcessedFlagByIdRequestBodyValidator);
    const {
        
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, SetEmbeddingsIsProcessedFlagByIdRequestParamsSanitizer, SetEmbeddingsIsProcessedFlagByIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, SetEmbeddingsIsProcessedFlagByIdRequestQuerySanitizer, SetEmbeddingsIsProcessedFlagByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/embeddings/{embeddingId}/trigger2dEmbeddingsJob

export const trigger2dEmbeddingsJobFN: RequestHandlerAsync<
    Trigger2dEmbeddingsJobRequestParams,
     | ApiErrorResponse,
    Trigger2dEmbeddingsJobRequestBody,
    Trigger2dEmbeddingsJobRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, Trigger2dEmbeddingsJobRequestBodySanitizer, Trigger2dEmbeddingsJobRequestBodyValidator);
    const {
        
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, Trigger2dEmbeddingsJobRequestParamsSanitizer, Trigger2dEmbeddingsJobRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, Trigger2dEmbeddingsJobRequestQuerySanitizer, Trigger2dEmbeddingsJobRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * EmbeddingsApi - interface
 *
 * @export
 * @interface EmbeddingsApiInterface
 */
export interface EmbeddingsApiInterface {
    /**
     * Deletes a embedding entry by id.
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    deleteEmbeddingByIdRaw(requestParameters: DeleteEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Deletes a embedding entry by id.
     */
    deleteEmbeddingById(requestParameters: DeleteEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Get all embeddings of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    getEmbeddingsByDatasetIdRaw(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetEmbeddingData>>>;
    /**
     * Get all embeddings of a dataset
     */
    getEmbeddingsByDatasetId(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetEmbeddingData>>;
    /**
     * Get all embeddings of a datasets sample
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {'full'} [mode] if we want everything (full) or just the summaries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    getEmbeddingsBySampleIdRaw(requestParameters: GetEmbeddingsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmbeddingData>>>;
    /**
     * Get all embeddings of a datasets sample
     */
    getEmbeddingsBySampleId(requestParameters: GetEmbeddingsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmbeddingData>>;
    /**
     * Get the url of a specific embeddings CSV
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    getEmbeddingsCSVReadUrlByIdRaw(requestParameters: GetEmbeddingsCSVReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Get the url of a specific embeddings CSV
     */
    getEmbeddingsCSVReadUrlById(requestParameters: GetEmbeddingsCSVReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Get the signed url to upload an CSVembedding to for a specific dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} [name] the sampling requests name to create a signed url for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    getEmbeddingsCSVWriteUrlByIdRaw(requestParameters: GetEmbeddingsCSVWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WriteCSVUrlData>>;
    /**
     * Get the signed url to upload an CSVembedding to for a specific dataset
     */
    getEmbeddingsCSVWriteUrlById(requestParameters: GetEmbeddingsCSVWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WriteCSVUrlData>;
    /**
     * Sets the isProcessed flag of the specified embedding
     * @param {SetEmbeddingsIsProcessedFlagByIdBodyRequest} setEmbeddingsIsProcessedFlagByIdBodyRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    setEmbeddingsIsProcessedFlagByIdRaw(requestParameters: SetEmbeddingsIsProcessedFlagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Sets the isProcessed flag of the specified embedding
     */
    setEmbeddingsIsProcessedFlagById(requestParameters: SetEmbeddingsIsProcessedFlagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Trigger job to get 2d embeddings from embeddings
     * @param {Trigger2dEmbeddingJobRequest} trigger2dEmbeddingJobRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmbeddingsApiInterface
     */
    trigger2dEmbeddingsJobRaw(requestParameters: Trigger2dEmbeddingsJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Trigger job to get 2d embeddings from embeddings
     */
    trigger2dEmbeddingsJob(requestParameters: Trigger2dEmbeddingsJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class EmbeddingsApi extends runtime.BaseAPI implements EmbeddingsApiInterface {
    /**
     * Deletes a embedding entry by id.
     */
    async deleteEmbeddingByIdRaw(requestParameters: DeleteEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling deleteEmbeddingById.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling deleteEmbeddingById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Deletes a embedding entry by id.
     */
    async deleteEmbeddingById(requestParameters: DeleteEmbeddingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmbeddingByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Get all embeddings of a dataset
     */
    async getEmbeddingsByDatasetIdRaw(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetEmbeddingData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddingsByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetEmbeddingDataFromJSON));
    }
    /**
     * Get all embeddings of a dataset
     */
    async getEmbeddingsByDatasetId(requestParameters: GetEmbeddingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetEmbeddingData>> {
        const response = await this.getEmbeddingsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all embeddings of a datasets sample
     */
    async getEmbeddingsBySampleIdRaw(requestParameters: GetEmbeddingsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmbeddingData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddingsBySampleId.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getEmbeddingsBySampleId.');
        }
        const queryParameters: any = {};
        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/users/datasets/{datasetId}/samples/{sampleId}/embeddings`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmbeddingDataFromJSON));
    }
    /**
     * Get all embeddings of a datasets sample
     */
    async getEmbeddingsBySampleId(requestParameters: GetEmbeddingsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmbeddingData>> {
        const response = await this.getEmbeddingsBySampleIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the url of a specific embeddings CSV
     */
    async getEmbeddingsCSVReadUrlByIdRaw(requestParameters: GetEmbeddingsCSVReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddingsCSVReadUrlById.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling getEmbeddingsCSVReadUrlById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/readCSVUrl`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Get the url of a specific embeddings CSV
     */
    async getEmbeddingsCSVReadUrlById(requestParameters: GetEmbeddingsCSVReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getEmbeddingsCSVReadUrlByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the signed url to upload an CSVembedding to for a specific dataset
     */
    async getEmbeddingsCSVWriteUrlByIdRaw(requestParameters: GetEmbeddingsCSVWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WriteCSVUrlData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getEmbeddingsCSVWriteUrlById.');
        }
        const queryParameters: any = {};
        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/writeCSVUrl`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => WriteCSVUrlDataFromJSON(jsonValue));
    }
    /**
     * Get the signed url to upload an CSVembedding to for a specific dataset
     */
    async getEmbeddingsCSVWriteUrlById(requestParameters: GetEmbeddingsCSVWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WriteCSVUrlData> {
        const response = await this.getEmbeddingsCSVWriteUrlByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Sets the isProcessed flag of the specified embedding
     */
    async setEmbeddingsIsProcessedFlagByIdRaw(requestParameters: SetEmbeddingsIsProcessedFlagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setEmbeddingsIsProcessedFlagByIdBodyRequest === null || requestParameters.setEmbeddingsIsProcessedFlagByIdBodyRequest === undefined) {
            throw new runtime.RequiredError('setEmbeddingsIsProcessedFlagByIdBodyRequest', 'Required parameter requestParameters.setEmbeddingsIsProcessedFlagByIdBodyRequest was null or undefined when calling setEmbeddingsIsProcessedFlagById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling setEmbeddingsIsProcessedFlagById.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling setEmbeddingsIsProcessedFlagById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/isProcessed`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmbeddingsIsProcessedFlagByIdBodyRequestToJSON(requestParameters.setEmbeddingsIsProcessedFlagByIdBodyRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Sets the isProcessed flag of the specified embedding
     */
    async setEmbeddingsIsProcessedFlagById(requestParameters: SetEmbeddingsIsProcessedFlagByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setEmbeddingsIsProcessedFlagByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Trigger job to get 2d embeddings from embeddings
     */
    async trigger2dEmbeddingsJobRaw(requestParameters: Trigger2dEmbeddingsJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trigger2dEmbeddingJobRequest === null || requestParameters.trigger2dEmbeddingJobRequest === undefined) {
            throw new runtime.RequiredError('trigger2dEmbeddingJobRequest', 'Required parameter requestParameters.trigger2dEmbeddingJobRequest was null or undefined when calling trigger2dEmbeddingsJob.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling trigger2dEmbeddingsJob.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling trigger2dEmbeddingsJob.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/trigger2dEmbeddingsJob`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Trigger2dEmbeddingJobRequestToJSON(requestParameters.trigger2dEmbeddingJobRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Trigger job to get 2d embeddings from embeddings
     */
    async trigger2dEmbeddingsJob(requestParameters: Trigger2dEmbeddingsJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.trigger2dEmbeddingsJobRaw(requestParameters, initOverrides);
    }
}
